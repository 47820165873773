var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AdminTable',{attrs:{"id":"admin-steam-bot-proxy","columns":_vm.columns,"title":`Коллекция firebase-tokens`,"model-name":"firebaseTokenModel"},scopedSlots:_vm._u([{key:"top-right",fn:function({ query }){return [_c('q-btn',{attrs:{"label":"Создать","color":"primary","push":"","size":"md","rounded":""},on:{"click":function($event){_vm.creationDialog = true}}}),_c('q-dialog',{model:{value:(_vm.creationDialog),callback:function ($$v) {_vm.creationDialog=$$v},expression:"creationDialog"}},[_c('q-card',{staticStyle:{"min-width":"500px"}},[_c('q-card-section',{staticClass:"row items-center q-pb-none"},[_c('div',{staticClass:"text-h6"},[_vm._v("Создание уведомления")]),_c('q-space'),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"icon":"close","flat":"","round":"","dense":""}})],1),_c('q-card-section',[_c('ApolloMutation',{attrs:{"mutation":gql => gql`
                mutation sendFirebaseMessagingNotification(
                  $notification: JSON!
                  $data: JSON
                ) {
                  sendFirebaseMessagingNotification(
                    notification: $notification
                    data: $data
                  )
                }
              `},on:{"error":error =>
                _vm.$q.notify({
                  message: error.message,
                  multiLine: true,
                  type: 'negative'
                }),"done":() => {
                query.refetch();
              }},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading, error }){return [_c('q-form',{on:{"submit":function($event){return _vm.submit(mutate)}}},[_c('q-input',{attrs:{"label":"Заголовок"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('q-input',{attrs:{"label":"Сообщение","type":"textarea"},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}}),_c('q-input',{attrs:{"label":"Картинка"},model:{value:(_vm.imageUrl),callback:function ($$v) {_vm.imageUrl=$$v},expression:"imageUrl"}}),_c('q-input',{attrs:{"label":"Ссылка"},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}}),_c('q-btn',{staticClass:"full-width q-mt-md",attrs:{"label":"Создать","loading":loading,"color":"primary","size":"lg","push":"","type":"submit"}})],1)]}}],null,true)})],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }