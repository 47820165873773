
import { Component, Vue } from "vue-property-decorator";
import AdminTable from "@/components/admin/AdminTable.vue";

@Component({
  components: {
    AdminTable
  }
})
export default class AdminSteamBotProxy extends Vue {
  title = "";
  body = "";
  imageUrl = "";
  url = "";
  creationDialog = false;
  columns = [
    {
      name: "_id",
      required: true,
      label: "_id",
      field: "_id",
      align: "left",
      type: "string"
    },
    {
      name: "deviceId",
      align: "center",
      label: "deviceId",
      field: "deviceId",
      type: "string"
    },
    {
      name: "token",
      align: "center",
      label: "token",
      field: "token",
      type: "string"
    },
    {
      name: "userIds",
      align: "center",
      label: "userIds",
      field: "userIds",
      type: "string"
    },
    {
      name: "createdAt",
      align: "center",
      label: "createdAt",
      field: "createdAt",
      type: "string"
    }
  ];

  async submit(mutate) {
    const notification = {
      title: this.title,
      body: this.body,
      ...(this.imageUrl ? { imageUrl: this.imageUrl } : {})
    };
    const data = this.url ? { url: this.url } : {};
    await mutate({
      variables: {
        notification,
        data
      }
    });
  }
}
